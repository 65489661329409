<template>
  <div
    class="cb-message"
    :class="{'is-bot' : data.isBot}">
    <span
      ref="icon"
      class="cb-message-icon">
      <avatar-bot v-if="data.isBot" />
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 48 48"
        style="enable-background:new 0 0 48 48;"
        xml:space="preserve">
        <circle
          style="fill:#EDE7F6;"
          cx="24"
          cy="24"
          r="20" />
        <rect
          x="18"
          y="18.25"
          style="fill:#4E342E;"
          width="12"
          height="13.5" />
        <path
          style="fill:#FF1744;"
          d="M24,44c4.482,0,8.605-1.492,11.94-3.982C35.326,32.641,28,31,28,31h-8c0,0-7.328,1.614-7.94,9.018
          C15.395,42.508,19.518,44,24,44z" />
        <path
          style="fill:#A0530E;"
          d="M24,34.75c-1.439,0-3.5-3.75-3.5-3.75v-5h7v5C27.5,31,25.439,34.75,24,34.75" />
        <path
          style="fill:#C16E14;"
          d="M31,23c0,3.728-3.272,7-7,7c-3.729,0-7-3.272-7-7v-6c0-3.728,14-5.726,14,0V23z M24,36
          c2.901,0,3.5-5,3.5-5s-1.864,3-3.5,3c-1.636,0-3.5-3-3.5-3S21.099,36,24,36" />
        <path
          style="fill:#212121;"
          d="M26,22c0-0.551,0.448-1,1-1s1,0.449,1,1s-0.448,1-1,1S26,22.551,26,22 M20,22c0,0.551,0.448,1,1,1
          s1-0.449,1-1s-0.448-1-1-1S20,21.449,20,22" />
        <path
          style="fill:#4E342E;"
          d="M24,11c-4.556,0-11,2.089-11,17l5,3.75V21l9-5l3,4v12l5-5c0-3.019-0.255-14.5-8-14.5L26,11H24z" />
      </svg>

    </span>
    <div
      ref="text"
      class="cb-message-text">
      <span class="time-hour">{{ timeHour }}</span>
      <template v-if="data.message !== '' ">
        <template>
          {{ data.message }}
          <a
            v-if="data.link"
            href=""
            class="accent--text"
            @click.prevent="action">{{ data.link }}</a>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
  import chatUiMixin from '@/mixins/chat-ui'
  import AvatarBot from '../AvatarBot'
  export default {
    components: { AvatarBot },
    mixins: [ chatUiMixin ],
    mounted () {
      this.iconAnimation()
      this.messageAnimation()
    },
    methods: {
      action () {
        this.$emit('action', true)
      }
    }
  }
</script>

<style lang="sass">
  @import '../../../assets/style/placeholder'

  $radius: 6px
  $heightFooter: 75px

  .cb-message
    margin-bottom: 30px
    right: 0
    align-self: flex-end
    flex-direction: row-reverse
    display: flex
    align-items: baseline

    a:hover
      text-decoration: none

    &-icon
      transform: translate(-100px)
      opacity: 0

      svg
        width: 50px
        height: 50px

    &-text
      // background: #f1f0f0
      color: $darkGray
      background: $light
      padding: 6px 12px
      border-radius: $radius *2
      border-top-right-radius: 0
      position: relative
      font-size: 18px
      top: 15px
      opacity: 0
      max-width: 40%
      @extend %default-shadow

    &.is-bot
      flex-direction: row
      align-self: flex-start
      left: 0

      .cb-message-text
        // background: lighten($action, 50%)
        // background: $light
        color: $darkGray
        border-radius: $radius *2
        border-top-right-radius: $radius *2
        border-top-left-radius: 0

      .cb-message-icon
        // transform: translate(-100px)

  .cb-message-spinner,
  .cb-message-spinner *
    box-sizing: border-box

  .cb-message-spinner
    height: 30px
    display: flex
    align-items: center

  @keyframes spinner-animation
    50%
      transform: scale(1)
      opacity: 1

    100%
      opacity: 0

  @media screen and (max-width: $max-mobile)
    .cb-message
      &-text
        max-width: 90%

      &-icon
        svg
          width: 40px
          height: 40px

</style>
