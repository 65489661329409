<template>
  <div class="tickets">
    <message :data="infoMessage" />
    <tickets-table
      :tickets="proposal.tickets"
      :proposal="proposal" />
  </div>
</template>

<script>
  import proposal from '../mocks/proposal'
  import Message from './Message'
  import TicketsTable from './TicketsTable'
  import chatUiMixin from '@/mixins/chat-ui'

  export default {
    components: { Message, TicketsTable },
    mixins: [ chatUiMixin ],
    data () {
      return {
        showModal: false,
        proposal: proposal, // mock
        infoMessage: {
          id: 71,
          isBot: true,
          message: '',
          input: false,
          options: null
        }
      }
    },
    computed: {
      isMobile () {
        return this.$vuetify.breakpoint.xsOnly
      }
    },
    mounted () {
      this.messageInfo()
    },
    methods: {
      messageInfo () {
        let message = this.isMobile ? 'Role a tela na horizontal para visualizar todos os dados' : 'Confira o detalhe da dívida'
        this.infoMessage = Object.assign({}, this.infoMessage, { message })
      }
    }
  }
</script>
