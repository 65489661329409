<template>
  <div
    ref="card"
    class="tickets-table">
    <v-data-table
      class="elevation-1 table-chat"
      :headers="headers"
      :items="updateTickets"
      hide-default-footer>
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.dueAt }}</td>
          <td class="text-center">
            {{ $n(item.originalValue, 'currency') }}
          </td>
          <td
            v-for="(detail, index) in item.details"
            :key="index"
            class="text-center">
            {{ $n(detail.value, 'currency') }}
          </td>
          <td class="text-center">
            {{ $n(item.currentValue, 'currency') }}
          </td>
        </tr>
        <tr v-if="updateTickets.indexOf(item) === updateTickets.length - 1 && !showAllTickets">
          <td
            colspan="7"
            class="text-md-right">
            <a
              href=""
              class="accent--text"
              @click.prevent="actionAllTickets">Ver toda a dívida</a>
          </td>
        </tr>
        <tr v-if="updateTickets.indexOf(item) === updateTickets.length - 1">
          <td class="text-center">
            <strong>Total</strong>
          </td>
          <td class="text-center">
            <strong>{{ $n(proposal.originalValue, 'currency') }}</strong>
          </td>
          <td
            v-for="(detail, index) in proposal.details"
            :key="index"
            class="text-center">
            {{ $n(detail.value, 'currency') }}
          </td>
          <td class="text-center">
            <strong>{{ $n(proposal.currentValue, 'currency') }}</strong>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  export default {
    props: {
      tickets: {
        type: Array,
        required: true
      },
      proposal: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        headers: [
          { text: 'Vencimento', value: 'date', align: 'center' },
          { text: 'Valor Original', value: 'original', align: 'center' },
          { text: 'Correção monetária', value: 'correction_index', align: 'center' },
          { text: 'Multa', value: 'mulfineta', align: 'center' },
          { text: 'Juros', value: 'interest_rate', align: 'center' },
          { text: 'Honorários', value: 'fee', align: 'center' },
          { text: 'Total', value: 'currentValue', align: 'center' }
        ],
        updateTickets: [],
        showAllTickets: null
      }
    },
    mounted () {
      this.hasManyTickets()
      this.scaleAnimation()
    },
    methods: {
      scaleAnimation () {
        const card = this.$refs.card

        this.anime({
          targets: card,
          easing: 'easeInOutQuad',
          delay: 800,
          scale: [
            { value: 0, duration: 100 },
            { value: 1, duration: 500 }
          ],
          opacity: [
            { value: 0, duration: 100 },
            { value: 1, duration: 200 }
          ]
        })
      },
      hasManyTickets () {
        if (this.tickets.length > 3) {
          this.updateTickets = this.tickets.slice(0, 3)
          this.showAllTickets = false
        } else {
          this.showResumeTickets = true
        }
      },
      actionAllTickets () {
        this.updateTickets = this.tickets
        this.showAllTickets = true
      }
    }
  }
</script>

<style lang="sass">
  .tickets-table
    opacity: 0
    transform: scale(0)
</style>
