<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 196 196"><defs /><title>MaisSolucoes_simbolo</title><g class="cls-1"><g
      id="Layer_1"
      data-name="Layer 1"><g class="cls-2"><g class="cls-3"><path
        class="cls-4"
        d="M84.78,185.41q-3.06,0-6.07-.26c-18.9-1.68-35.45-11-47.86-26.86h0c-13.86-17.75-19.23-40-15.11-62.6,3.93-21.6,16.19-42.56,34.52-59,20.53-18.41,45.27-27.81,67.9-25.81,18.9,1.68,35.45,11,47.86,26.85,13.86,17.75,19.23,40,15.12,62.6-3.94,21.61-16.2,42.56-34.52,59C127.9,176.11,105.69,185.41,84.78,185.41ZM36.38,154c11.18,14.32,26,22.68,42.95,24.18,20.7,1.84,43.51-6.93,62.6-24,17.18-15.4,28.65-34.94,32.31-55,3.8-20.92-1-40.64-13.74-57-11.19-14.32-26-22.68-43-24.18C96.84,16,74,24.81,54.94,41.92c-17.18,15.4-28.65,34.94-32.3,55-3.81,20.92.94,40.64,13.74,57Z" /></g><g class="cls-3"><path
          class="cls-5"
          d="M115.26,188.05c-23.36,0-48.57-12.56-69.18-34.46h0C8.1,113.23,3,62.16,33.73,29.39c13.2-14.1,29.79-21.56,48-21.58h.07c23.37,0,48.58,12.56,69.19,34.46,38,40.36,43.05,91.44,12.35,124.21-13.2,14.09-29.8,21.55-48,21.57ZM81.78,14.83h-.06c-16.2,0-31,6.71-42.87,19.35C24.65,49.33,18.58,68.59,21.31,89.87c2.59,20.27,13.2,41.2,29.87,58.91h0C70.47,169.28,93.82,181,115.26,181h.07c16.2,0,31-6.71,42.87-19.36,14.2-15.15,20.26-34.41,17.54-55.69-2.6-20.27-13.21-41.19-29.88-58.91C126.57,26.58,103.22,14.83,81.78,14.83Z" /></g><g class="cls-3"><path
            class="cls-6"
            d="M85,172.46c-18.7,0-35.88-6.07-49.4-17.64C19.43,141,10.51,120.79,10.51,98s8.92-42.95,25.11-56.81C52.46,26.8,75,20.93,99.07,24.69c23.18,3.62,45,13.1,61.44,26.7,16.79,13.87,26,30.43,26,46.63s-9.24,32.76-26,46.63c-16.44,13.59-38.26,23.07-61.44,26.7A91.44,91.44,0,0,1,85,172.46Zm0-141.86c-17,0-32.65,5.48-44.87,15.94C25.57,59.05,17.52,77.33,17.52,98s8.05,39,22.66,51.47C55.44,162.56,76,167.86,98,164.42c45-7,81.55-36.82,81.55-66.4S142.94,38.65,98,31.62A83.06,83.06,0,0,0,85.05,30.6Z" /></g></g><path
              class="cls-7"
              d="M128.6,128.5V89.6c0-4.7-.5-10.4-6.7-10.4a17.72,17.72,0,0,0-9.8,3.3,56.53,56.53,0,0,1,.4,5.9v27.4c0,2,.1,2.3,1.9,2.5l4.8.7v9.5h-25V89c0-4.5-.4-9.8-6.4-9.8a16.49,16.49,0,0,0-10,3.9v32.7c0,2,.1,2,1.9,2.4l5.9.8v9.5H52.3V119l5.4-.8c1.7-.2,1.9-.6,1.9-2.4V80.3c0-1.3-.4-2.2-1.6-2.5l-6.5-2.3,1.3-9.8H74L75,72l.6.1c5.4-4.5,11.6-7.6,19.3-7.6,5.9,0,11.3,1.6,14.7,7.2,4-3.1,11-7.2,19.4-7.2,12.9,0,17.7,6.4,17.7,20.4v30.8c0,1.9.1,2.3,1.9,2.5l6,.7v9.5h-26Z" /></g></g></svg>
</template>

<script>
  export default {

  }
</script>

<style>
  .cls-1,.cls-2{
    isolation:isolate;
  }
  .cls-2, .cls-3{
    mix-blend-mode:hard-light;
  }
  .cls-4{fill:#9f2300;}
  .cls-5{fill:#f8c91c;}
  .cls-6{fill:#f59d23;}
  .cls-7{fill:#494949;}
</style>
